// Generated by Framer (99bc0fd)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["oeZ3rqbC3", "HuwfY9ayv", "LBJMWIlVd"];

const serializationHash = "framer-kY88d"

const variantClassNames = {HuwfY9ayv: "framer-v-u055t5", LBJMWIlVd: "framer-v-ywt5td", oeZ3rqbC3: "framer-v-1w584as"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Desktop (Small)": "HuwfY9ayv", Desktop: "oeZ3rqbC3", Tablet: "LBJMWIlVd"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "oeZ3rqbC3"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oeZ3rqbC3", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.framer.com/marketplace/templates/najmai?via=mejed_k&fp_sid=uforf"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1w584as", className, classNames)} framer-6qc08i`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"oeZ3rqbC3"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c3b5f6a7-251b-4724-88fe-6468b771ecc8, rgb(153, 84, 242))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 0.636953px 1.14652px -1.125px rgba(0, 0, 0, 0.26), 0px 1.9316px 3.47689px -2.25px rgba(0, 0, 0, 0.24), 0px 5.10612px 9.19102px -3.375px rgba(0, 0, 0, 0.19), 0px 16px 28.8px -4.5px rgba(0, 0, 0, 0.03)", ...style}} {...addPropertyOverrides({HuwfY9ayv: {"data-framer-name": "Desktop (Small)"}, LBJMWIlVd: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-line-height": "27px", "--framer-text-color": "var(--extracted-a0htzi, var(--token-bb10edb4-77b4-4b3d-ab41-b924f31d59e7, rgb(255, 255, 255)))"}}>Use for FREE</motion.h3></React.Fragment>} className={"framer-1zvgb"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"tpeTNzjNd"} style={{"--extracted-a0htzi": "var(--token-bb10edb4-77b4-4b3d-ab41-b924f31d59e7, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kY88d.framer-6qc08i, .framer-kY88d .framer-6qc08i { display: block; }", ".framer-kY88d.framer-1w584as { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 142px; will-change: var(--framer-will-change-override, transform); }", ".framer-kY88d .framer-1zvgb { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kY88d.framer-1w584as { gap: 0px; } .framer-kY88d.framer-1w584as > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-kY88d.framer-1w584as > :first-child { margin-top: 0px; } .framer-kY88d.framer-1w584as > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HuwfY9ayv":{"layout":["fixed","fixed"]},"LBJMWIlVd":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIkxmXSVeS: React.ComponentType<Props> = withCSS(Component, css, "framer-kY88d") as typeof Component;
export default FramerIkxmXSVeS;

FramerIkxmXSVeS.displayName = "Get it for FREE";

FramerIkxmXSVeS.defaultProps = {height: 36, width: 142};

addPropertyControls(FramerIkxmXSVeS, {variant: {options: ["oeZ3rqbC3", "HuwfY9ayv", "LBJMWIlVd"], optionTitles: ["Desktop", "Desktop (Small)", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerIkxmXSVeS, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})